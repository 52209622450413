export default {
  mixins: [],
  data() {
    return {};
  },
  methods: {
    addressToString(address) {
      var addressArray = [];
      // add each element of the address to the array if it exists, otherwise do nothing;
      address.address ? addressArray.push(address.address) : '';
      address.city ? addressArray.push(address.city) : '';
      address.state ? addressArray.push(address.state) : '';
      address.zip_code ? addressArray.push(address.zip_code) : '';
      address.country ? addressArray.push(address.country) : '';

      return addressArray.join(', ');
    },
  },
};
